<template>
    <div class="row ">
      <div class="title-nav">
        <h2>{{ $t("Attachments") }}</h2>
        <div class="d-flex align-items-center">
        </div>
      </div>
        <div class="d-flex align-items-center flex-wrap">
            <div @click="downloadFile(item)" class="item-flie-uploaded" v-show="imageId.length > 0"
                v-for="(item, index) in imageId" :key="index">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.14142 10.3586C7.06332 10.4367 6.93668 10.4367 6.85858 10.3586L2.76396 6.26396C2.68682 6.18682 2.68573 6.0621 2.7615 5.98362L3.70862 5.00268C3.7863 4.92223 3.91485 4.9211 3.99392 5.00017L5.78358 6.78983C5.90957 6.91582 6.125 6.82659 6.125 6.64841V0.2C6.125 0.0895427 6.21454 0 6.325 0H7.675C7.78546 0 7.875 0.0895431 7.875 0.2V6.64841C7.875 6.82659 8.09043 6.91582 8.21642 6.78983L10.0061 5.00017C10.0852 4.9211 10.2137 4.92223 10.2914 5.00268L11.2385 5.98362C11.3143 6.0621 11.3132 6.18682 11.236 6.26396L7.14142 10.3586ZM1.75 14C1.26875 14 0.856917 13.8288 0.5145 13.4864C0.172083 13.144 0.000583333 12.7318 0 12.25V9.825C0 9.71454 0.0895431 9.625 0.2 9.625H1.55C1.66046 9.625 1.75 9.71454 1.75 9.825V12.05C1.75 12.1605 1.83954 12.25 1.95 12.25H12.05C12.1605 12.25 12.25 12.1605 12.25 12.05V9.825C12.25 9.71454 12.3395 9.625 12.45 9.625H13.8C13.9105 9.625 14 9.71454 14 9.825V12.25C14 12.7312 13.8288 13.1434 13.4864 13.4864C13.144 13.8294 12.7318 14.0006 12.25 14H1.75Z"
                        fill="#0a3041" />
                </svg>
                {{ item.name }}
            </div>
            <label class="imageId" for="imageId">
                <svg width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.45645 12.6206C4.46713 13.5696 1.5 17.157 1.5 21.4375C1.5 26.4426 5.55656 30.5 10.5606 30.5C11.419 30.5 12.2495 30.3806 13.0364 30.1575M30.5435 12.6206C34.5329 13.5696 37.5 17.157 37.5 21.4375C37.5 26.4426 33.4435 30.5 28.4394 30.5C27.581 30.5 26.7505 30.3806 25.9636 30.1575M30.4939 12.375C30.4939 6.3689 25.626 1.5 19.6212 1.5C13.6164 1.5 8.74847 6.3689 8.74847 12.375M13.3382 19.5173L19.6213 13.2122L26.0832 19.6252M19.6212 28.6873V16.4184"
                        stroke="#225476" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>
                  {{ $t('List your files') }}
                </span>
            </label>
            <input accept="*" type="file" id="imageId" for="imageId" hidden
                @change="SetimageId">
        </div>

    </div>
</template>
<script>
export default {
  data() {
    return {
      imageId:[],
    }
  },
  methods:{
    SetimageId(e){
      this.imageId.push(event.target.files[0])
    },
    downloadFile(file) {
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      link.click();
      URL.revokeObjectURL(url);
    },
  }

};
</script>
